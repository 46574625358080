import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { LogtoProvider, LogtoConfig, UserScope } from '@logto/react'
import {BrowserRouter} from "react-router-dom";

const logtoConfig: LogtoConfig = {
    endpoint: import.meta.env.VITE_LOGTO_ENDPOINT,
    appId: import.meta.env.VITE_LOGTO_APP_ID,
    scopes: [
        "all", "admin",
        UserScope.Email,
    ],
    resources: [
        import.meta.env.VITE_LOGTO_API_ID
    ]
}

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            'potio-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
                customer?: string;
                action?: string;
                'data-key'?: string;
                'customer-email'?: string;
            }
        }
    }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <LogtoProvider config={logtoConfig}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </LogtoProvider>
    </React.StrictMode>,
)
