import './App.css'
import {Route, Routes} from "react-router-dom"
import Home from "./pages/Home"
import SignInCallback from "./pages/SignInCallback.tsx"
import Pricing from './pages/Pricing/index.tsx'


function App() {
    return (
        <>
            <Routes>
                <Route index Component={Home}/>
                <Route path="/pricing" Component={Pricing}/>
                <Route path="/sign-in-callback" Component={SignInCallback}/>
            </Routes>
        </>
    )
}

export default App
